import React, { useState } from 'react'
import LoginSidebar from './LoginSidebar'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ApiConnection from '../../utils/ApiConnection'
import Loader from '../../utils/Loader'

const ChangePassword = () => {
    const [loading, setloading] = useState(false)
    const [oldpassword, setoldpassword] = useState("")
    const [newpassword, setnewpassword] = useState("")
    const [confirmpassword, setconfirmpassword] = useState("")

    let navigate = useNavigate()

    const SubmitHandler = async () =>{
        if(oldpassword == ''){
            toast.error("Existing password is required");
          } else if(newpassword == ''){
            toast.error("New Password is required");
          } else if(newpassword !== confirmpassword){
            toast.error("Confirm Password does not match");
          } else {
            setloading(true)
            try{
                var FormData = require('form-data');
                var data = new FormData();
                data.append('existing_password', oldpassword);
                data.append('password', newpassword);
                data.append('c_password', confirmpassword);
                const response = await ApiConnection.post(`change-password`, data);
                if(response.status === 200){
                    setloading(false)
                    toast.success(response?.data?.message);
                    navigate("/dashboard")
                } else {
                    setloading(false)
                }

            } catch(err){
                setloading(false)
                if(err.response.status === 500){
                      toast.error(err.response?.data?.message);   
                  
                }
        }
          }
    }


    return (
        <div className='afterlog-sec'>
             {loading && <Loader/>}
        <div className='container'>
            <div className='row'>
                <div className='col-lg-3'>
                    <LoginSidebar />
                </div>
                <div className='col-lg-9'>
                <div className='afterlog-sec-right'>
                <h2 className='headingStyle text-left'>Change Password</h2>
            <div className='row'>
            <div className='col-lg-6'>
                <div className='form-group'>
                    <label>Existing Password</label>
                    <input type="password" className="form-control" placeholder="XXXXXXX"
                      value={oldpassword}  
                      onChange={(e)=>setoldpassword(e.target.value)}
                    />
                </div>
            </div>
            <div className='col-lg-6'>
                <div className='form-group'>
                    <label>New Password</label>
                    <input type="password" className="form-control" placeholder="XXXXXXX"
                    value={newpassword}  
                    onChange={(e)=>setnewpassword(e.target.value)}
                    />
                </div>
            </div>
            <div className='col-lg-6'>
                <div className='form-group'>
                    <label>Confirm New Password</label>
                    <input type="password" className="form-control" placeholder="XXXXXXX"
                     value={confirmpassword}  
                     onChange={(e)=>setconfirmpassword(e.target.value)}
                    />
                </div>
            </div>
          
            <div className='col-lg-12 mt-2'>
     <div className='form-group text-right'>
                <button type='button' className='submitBtn' onClick={SubmitHandler}>Update</button>
            </div>
            </div>
            </div>
                </div>
            </div>
            </div>
        </div>
    </div>
      )
}

export default ChangePassword