import React,{useEffect, useState} from 'react'
import { IMAGE } from '../../common/Theme'
import { useDispatch, useSelector } from 'react-redux';
import { sidebarAction } from '../../redux/SidebarSlice';
import { AxiosRequest } from '../../utils/AxiosRequest';
import Loader from '../../utils/Loader';

const WeekWiners = () => {


    const sidebarShow = useSelector((state) => state.SidebarSlice.isSidebarshow)
    const dispatch = useDispatch()
    const {loading, GetVideo, videoList} = AxiosRequest()



    useEffect(()=>{
      dispatch(sidebarAction(!sidebarShow)) 
      GetVideo('video_type=all')
    },[])







  return (
    <div className='about-sec'>
              {loading && <Loader/>}
    <div className='container'>
        <h2 className='headingStyle'>For Your Information</h2>
        <div className='row'>
            {videoList&&videoList.map((item, index)=>{
                return (
                    <div className='col-lg-12' key={index}>
                    <div className='youyube-box mb-5'>
                    <iframe width="100%" height="300" src={item?.link} title={item?.name} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <h5>{item?.name} {item?.start_date} – {item?.end_date}</h5>
                    </div>
                </div>
                )
            })}
          
        </div>
    </div>
</div>
  )
}

export default WeekWiners