import React, { useEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion';

const Faq = ({data, sitedata}) => {



  return (
    <div className='faq-sec'>
         <div className='container'>
            <h2 className='headingStyle'>{sitedata?.faq_section_heading}</h2>

            <Accordion defaultActiveKey="0">
            {data&&data.map((item, index)=>{
          return (
            <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>{item?.question}</Accordion.Header>
            <Accordion.Body>
             {item?.answer}
            </Accordion.Body>
          </Accordion.Item>
          )
        })}
    </Accordion>
        </div>
    </div>
  )
}

export default Faq