import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import Loader from '../../utils/Loader';
import ApiConnection from '../../utils/ApiConnection';


const Login = () => {
    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    const [loading, setloading] = useState(false)
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState( <i class="fa-solid fa-eye-slash"></i>);

    const navigate = useNavigate()

    const handleLogin = async ()=>{
    

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if (reg.test(email) === false) {

            toast.error('Email should be proper!');
        } else if(password == ""){
            toast.error('Password should be must');
        } else {
            setloading(true)
            var FormData = require('form-data');
            var data = new FormData();
            data.append('email', email);
            data.append('password', password);
    
            try{
                const response = await ApiConnection.post('login', data)
                if(response?.data?.success) {
                    setloading(false)
                    console.log("user Info", response?.data?.token)
                    if(response?.data?.data?.token !== ""){
                        localStorage.setItem('ride_access_token', response?.data?.data?.token)
                        localStorage.setItem('user', JSON.stringify(response?.data?.data?.user));
                  
                        navigate('/dashboard')
                        window.location.reload()
                } else {
                    toast.info(response?.data?.message)
                    setloading(false)
                }
            }else {
                toast.info(response?.data?.message)
                setloading(false)
            }
            } catch(err){
                setloading(false)
                if(err.response?.status === 401){
                toast.error(err.response?.data?.message);   
            }
        }
    }

        

        //navigate("/dashboard")
    }

 
    const handleToggle = () => {
        if (type==='password'){
           setIcon(<i class="fa-solid fa-eye"></i>);
           setType('text')
        } else {
           setIcon(<i class="fa-solid fa-eye-slash"></i>)
           setType('password')
        }
     }


  return (
    <div className='container auth-form py-5'>
            {loading && <Loader/>}
    <h2>Login</h2>
    <div className='row'>
        
        <div className='col-lg-5'>
            <div className='form-group'>
                <label>Email</label>
                <input type="email" className="form-control" placeholder="Email"
                value={email}
                onChange={(e)=>setemail(e.target.value)}
                />
            </div>
     
            <div className='form-group'>
                <label>Password</label>
                <input  type={type} className="form-control" placeholder="XXXXX"
                value={password}
                onChange={(e)=>setpassword(e.target.value)}
                />
                <span class="flex justify-around items-center" style={{    
                    position: 'absolute',
top: '43px',
right: '18px'}} onClick={handleToggle}>
               {icon}
                  
                </span>
            </div>
     
            <div className='form-group'>
            <h5 className='text-left'> <input type='checkbox'/> Keep me logged in </h5>
            </div>
   
            <div className='form-group text-left d-flex align-items-center justify-content-between'>
                <button type='button' className='submitBtn' onClick={handleLogin}>Login</button>
                <h6 className='ml-2'> Forgot Password? <NavLink to="/forgot-password">Click Here</NavLink> </h6>
            </div>
        </div>
      
        <div className='col-lg-12 my-3'>
            <div className='form-group text-left'>
                <h6> New User? <NavLink to="/sign-up">Click Here</NavLink> </h6>
            </div>
        </div>
    </div>
</div>
  )
}

export default Login