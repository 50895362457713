import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { BaseUrl } from '../../utils/ApiConnection'

const AboutHome = ({data, sitedata}) => {


  return (
    <div className='about-sec'>
        <div className='container'>
            <h2 className='headingStyle'>{sitedata?.homepage_about_text_heading}</h2>
            <div className='row'>
                <div className='col-lg-6'>
                  <img src={BaseUrl + data?.image} className='w-100' alt="about" />
                {/* <iframe width="100%" height="250" src="https://www.youtube.com/embed/ZhdwYMfb79I" title="DaySchedule" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                </div>
                <div className='col-lg-6'>
                <div dangerouslySetInnerHTML={{ __html: data?.description }} />
                        <NavLink to="/about-us">Read More</NavLink>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutHome