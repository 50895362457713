import React,{useEffect, useState} from 'react'
import { IMAGE } from '../../common/Theme'
import { useDispatch, useSelector } from 'react-redux';
import { sidebarAction } from '../../redux/SidebarSlice';
import { AxiosRequest } from '../../utils/AxiosRequest';
import Loader from '../../utils/Loader';
import { BaseUrl } from '../../utils/ApiConnection';

const NowuKnow = () => {

    const sidebarShow = useSelector((state) => state.SidebarSlice.isSidebarshow)
    const { loading, Getcms, cmsdata} = AxiosRequest()
    const dispatch = useDispatch()
    useEffect(()=>{
      dispatch(sidebarAction(!sidebarShow)) 

      Getcms('Now-U-Know')
      
    
    },[])




  return (
    <div className='about-sec'>
         {loading && <Loader/>}
    <div className='container'>
        <h2 className='headingStyle'>{cmsdata?.page_title}</h2>
        <div className='row'>
            <div className='col-lg-12'>
                <div className='youyube-box mb-5'>
                    <img src={BaseUrl + cmsdata?.image} className='w-100 mb-2' />
                    {/* <h3>In publishing and graphic design</h3> */}
                    <div dangerouslySetInnerHTML={{ __html: cmsdata?.description }} />
                </div>
            </div>
           
        </div>
    </div>
</div>
  )
}

export default NowuKnow