import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IMAGE } from '../../common/Theme';
import { BaseUrl } from '../../utils/ApiConnection';

const Testimonial = ({data, sitedata}) => {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        slickPrev: false,
        slickNext: false,
        autoplaySpeed:5000
      };


    



  return (
    <div className='testimonial-sec'>
            <div className='container'>
                <div className='testimonial-heading'>
                    <h2 className='headingStyle'>{sitedata?.testimonial_section_heading}</h2>
                </div>
        <Slider {...settings}>
              {data&&data.map((item, index)=>{
       
                return (
                  <div className="testimonial-box" key={index}>
               
                  <div dangerouslySetInnerHTML={{ __html: item?.description }} />
        

                <div className="testi-pro">
                  <div className="testi-pro-img">
                    <img src={BaseUrl +  item?.image} alt="client"  />
                  </div>
                  <div className="testi-pro-info">
                    <h4>{item?.author}</h4>
                    <span>{item?.designation}</span>
                  </div>
                </div>
              </div>
                )
              })}
          
            </Slider>
            </div>
    </div>
  )
}

export default Testimonial