import React,{useEffect} from 'react'
import { IMAGE } from '../../common/Theme'
import { useDispatch, useSelector } from 'react-redux';
import { sidebarAction } from '../../redux/SidebarSlice';
import { AxiosRequest } from '../../utils/AxiosRequest';
import Loader from '../../utils/Loader';
import { BaseUrl } from '../../utils/ApiConnection';

const TermsandCondition = () => {
    const sidebarShow = useSelector((state) => state.SidebarSlice.isSidebarshow)
    const { loading, Getcms, cmsdata} = AxiosRequest()
    const dispatch = useDispatch()
    useEffect(()=>{
      dispatch(sidebarAction(!sidebarShow)) 
      Getcms('terms-conditions')
    },[])




  return (
    <div className='about-sec'>
    {loading && <Loader/>}
<div className='container'>
    <h2 className='headingStyle'>{cmsdata?.page_title}</h2>
    <div dangerouslySetInnerHTML={{ __html: cmsdata?.description }} />
</div>
</div>
  )
}

export default TermsandCondition