import React,{useEffect} from 'react'
import { IMAGE } from '../../common/Theme'
import { useDispatch, useSelector } from 'react-redux';
import { sidebarAction } from '../../redux/SidebarSlice';
import { AxiosRequest } from '../../utils/AxiosRequest';
import Loader from '../../utils/Loader';
import { BaseUrl } from '../../utils/ApiConnection';
const About = () => {

  const sidebarShow = useSelector((state) => state.SidebarSlice.isSidebarshow)
  const { loading, Getcms, cmsdata} = AxiosRequest()
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(sidebarAction(!sidebarShow)) 
    Getcms('welcome')
  },[])



  
  return (
    <div className='about-sec'>
        {loading && <Loader/>}
    <div className='container'>
        <h2 className='headingStyle'>{cmsdata?.page_name}</h2>
        <div className='row'>
        <div className='col-lg-7'>
        <div dangerouslySetInnerHTML={{ __html: cmsdata?.description }} />
                  
            </div>
            <div className='col-lg-5'>
            <img src={BaseUrl + cmsdata?.image} className='w-100' alt="about" />
            </div>
           {/* <div className='col-lg-12'>
            <p>Sed viverra, elit id tristique maximus, justo ante venenatis sapien, eu posuere massa mi quis elit. Nam vitae velit augue. Suspendisse pharetra vulputate mi nec interdum. Cras mollis consequat elit nec aliquet. Quisque luctus orci lectus, et lobortis arcu ullamcorper nec. Nulla ligula arcu, porttitor quis eleifend nec, gravida at tortor. Phasellus est magna, tincidunt sit amet porttitor eget, lobortis non purus.
Maecenas at erat a est vehicula cursus. Vestibulum lacinia massa leo, at hendrerit ipsum vulputate vitae. Nunc tincidunt consequat dictum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas molestie est ipsum, in aliquam metus imperdiet in. Integer faucibus fringilla quam posuere eleifend. Nunc eu lectus sit amet urna mollis mattis. Mauris efficitur est eget est egestas, quis tempus urna eleifend.
</p>
           </div> */}
        </div>
    </div>
</div>
  )
}

export default About