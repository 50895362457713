import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux'
import SidebarSlice from './SidebarSlice';


const rootReducer = combineReducers({ 
    SidebarSlice: SidebarSlice,
})

export const store = configureStore({
    reducer: rootReducer,
  })