import React, { useEffect, useState } from 'react'
import LoginSidebar from './LoginSidebar'
import Modal from 'react-bootstrap/Modal';
import { Rating } from 'react-simple-star-rating'
import Loader from '../../utils/Loader';
import ApiConnection from '../../utils/ApiConnection';
import moment from 'moment';
import { toast } from 'react-toastify';

const CancelRide = () => {
    const [loading, setloading] = useState(false)
    const [triplist, settriplist] = useState([])
    const [cancelmodal, setcancelmodal] = useState(false);
    const handleClose = () => setcancelmodal(false);

    const GetTrip = async () =>{

        setloading(true)
    
        try {
    
            const response = await ApiConnection.get('trips?status=cancelled')
            setloading(false)
            if(response?.status === 200) {
                settriplist(response?.data?.data?.list)
            }
    
        } catch (e){  setloading(false)}
    
    }
    
    useEffect(()=>{
        GetTrip()
    },[])


    const RebookHandle = async (id)=>{
        setloading(true)
    
        try {
    
            const response = await ApiConnection.post(`trips/rebook/${id}`)
            setloading(false)
            if(response?.data?.success) {
                toast.success(response?.data?.message)
                GetTrip()
            } else {
                toast.error(response?.data?.message)
            }
    
        } catch (e){  setloading(false)}
    }

  return (
    <div className='afterlog-sec'>
    {loading && <Loader/>}
<div className='container'>
    <div className='row'>
        <div className='col-lg-3'>
            <LoginSidebar />
        </div>
        <div className='col-lg-9'>
        <div className='afterlog-sec-right'>
        <h2 className='headingStyle text-left'>Canceled Rides</h2>

{triplist.length > 0 ?
triplist&&triplist.map((item, i)=>{
    return (
        <div className='ride-list'>
        <ul>
        <li>
                        <p><b>Pickup Date: </b>: {moment(item?.pickup_date).format('DD-MM-YYYY')} </p>
                    </li>
                    <li>
                        <p><b>Pickup Time: </b>: {moment(item?.pickup_date).format('LT')} </p>
                    </li>
                    <li>
                        <p><b>Pickup Location: </b>: {item?.pickup_location}  </p>
                    </li>
                    <li>
                        <p><b>Drop Location: </b> {item?.drop_location}  </p>
                    </li>
                    <li>
                        <p><b>Number of Stops: </b> {item?.stops.length} </p>
                    </li>
                    <li>
                        <p><b>Estimated Fare: </b>  $ {item?.final_fare} </p>
                    </li>
        </ul>
        {/* <div className='ride-button'>
            <button className='btn btn-sm btn-primary' onClick={()=>RebookHandle(item?.id)}>Rebook</button>
        </div> */}
    </div>
    )
})
:

<h5>No Record found</h5>

}

        
        </div>  
    </div>  
    </div>  
</div>

</div>
  )
}

export default CancelRide