import React from 'react'
import { NavLink } from 'react-router-dom'
import { AxiosRequest } from '../utils/AxiosRequest'
import { IMAGE } from './Theme'

const Footer = ({data}) => {


  return (
    <div className='footer'>
        <div className='footer-top'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-7'>
                        <h3>{data?.contact_us_header}</h3>
                        <p>{data?.contact_us_label}</p>
                    </div>
                    <div className='col-lg-5'>
                       <input className="form-control" type='text' placeholder='Email Address' />
                       <button className='nsubmitBtn'>
                            <img src={IMAGE.paper} />
                       </button>
                    </div>
                </div>
            </div>
        </div>
        <div className='footer-bottom'>
             <div className='container'>
                <ul>
                    <li>
                        <NavLink to="/privacy-policy">Privacy Policy </NavLink>    |   <NavLink to="/terms-condition">    Terms and Conditions</NavLink>
                            <NavLink to="/contact-us"  className={({ isActive }) => (isActive ? "active" : undefined)}> | Contact Us</NavLink>
                      
                    </li>
                    <li>
                        <NavLink>{data?.copyright_statement}</NavLink>
                    </li>
                    <li>
                        <a target='_blank' href='https://itiffyconsultants.com'>{data?.designed_by}</a>
                    </li>
                </ul>
             </div>
        </div>
    </div>
  )
}

export default Footer