import React from 'react'
import { IMAGE } from '../../common/Theme'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BaseUrl } from '../../utils/ApiConnection';


const Banner = ({data}) => {

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
  
  };

  return (
    <div className='banner'>
      
        <Slider {...settings}>
          {data&&data.map((item, index)=>{
            return (
              <div key={index}>
                  <h3>{item?.banner_name}</h3>
              <img src={BaseUrl + item?.banner_image} alt="banner" />
              <h3>{item?.banner_text}</h3>
              </div>
            )
          })}
   
    </Slider>
       
   
    </div>
  )
}

export default Banner