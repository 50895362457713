import React,{useEffect} from 'react'
import Banner from './Banner'
import AboutHome from './AboutHome'
import Weare from './Weare'
import Faq from './Faq'
import Testimonial from './Testimonial'
import { useDispatch, useSelector } from 'react-redux';
import { sidebarAction } from '../../redux/SidebarSlice';
import { AxiosRequest } from '../../utils/AxiosRequest'
import Loader from '../../utils/Loader'

const Index = () => {
  const sidebarShow = useSelector((state) => state.SidebarSlice.isSidebarshow)
  const dispatch = useDispatch()
  const { loading, GetHomepagecontent, homedata, Getcms, cmsdata, sitedata, Getsite} = AxiosRequest()

  useEffect(()=>{
    GetHomepagecontent()
    Getcms('welcome')
    Getsite()
  },[])

  return (
    <>
      {loading && <Loader/>}
    <Banner data={homedata?.banners}  />
    <AboutHome data={cmsdata} sitedata={sitedata} />
    <Weare data={homedata?.testimonial_1} sitedata={sitedata} />
    <Faq data={homedata?.faq} sitedata={sitedata} />
    <Testimonial  data={homedata?.testimonial_2} sitedata={sitedata} />
    </>
  )
}

export default Index