import React,{useEffect, useState} from 'react'
import { IMAGE } from '../../common/Theme'
import { useDispatch, useSelector } from 'react-redux';
import { sidebarAction } from '../../redux/SidebarSlice';
import { AxiosRequest } from '../../utils/AxiosRequest';
import Loader from '../../utils/Loader';

const LiveStreming = () => {



  const sidebarShow = useSelector((state) => state.SidebarSlice.isSidebarshow)
  const dispatch = useDispatch()
  const {loading, GetVideo, videoList} = AxiosRequest()


  useEffect(()=>{
    dispatch(sidebarAction(!sidebarShow)) 
    GetVideo('video_type=live')
  },[])

  return (
    <div className='about-sec'>
          {loading && <Loader/>}
    <div className='container'>
        <h2 className='headingStyle'>Live Streaming</h2>

        {videoList&&videoList.map((item, index)=>{
          return (
            <div className='live-str' key={index}>
            <iframe width="100%" height="400" src={item?.link}
             title={item?.name} frameborder="0" 
             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
             referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          )
        })}
         
          
        </div>
   
</div>
  )
}

export default LiveStreming