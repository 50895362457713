import React, { useEffect, useState } from 'react'
import LoginSidebar from './LoginSidebar'
import Modal from 'react-bootstrap/Modal';
import { Rating } from 'react-simple-star-rating'
import Loader from '../../utils/Loader';
import ApiConnection from '../../utils/ApiConnection';
import moment from 'moment';
import { toast } from 'react-toastify';
const CompletedRides = () => {
    const [loading, setloading] = useState(false)
    const [triplist, settriplist] = useState([])
    const [cancelmodal, setcancelmodal] = useState(false);
    const [feedback, setfeedback] = useState('');
    const [rowId, setrowId] = useState('')
    
    const handleClose = () => setcancelmodal(false);
    const [rating, setRating] = useState(0)
  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate)

    // other logic
  }
  // Optinal callback functions
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value, index) => console.log(value, index)




  const GetTrip = async () =>{

    setloading(true)

    try {

        const response = await ApiConnection.get('trips?status=completed')
        setloading(false)
        if(response?.status === 200) {
            settriplist(response?.data?.data?.list)
        }

    } catch (e){  setloading(false)}

}

useEffect(()=>{
    GetTrip()
},[])



const RebookHandle = async (id)=>{
    setloading(true)

    try {

        const response = await ApiConnection.post(`trips/rebook/${id}`)
        setloading(false)
        if(response?.data?.success) {
            toast.success(response?.data?.message)
            GetTrip()
        } else {
            toast.error(response?.data?.message)
        }

    } catch (e){  setloading(false)}
}

const rateHandle = async (id) =>{
    setrowId(id)
    setcancelmodal(true)
}

const SubmitHandle = async () =>{
    if(feedback == ''){
        toast.error('Feedback is mandatory')
    } else {
        setloading(true)
        var FormData = require('form-data');
        var data = new FormData();
        data.append('trip_id', rowId);
        data.append('feedback', feedback);
        data.append('rate', rating);
        

        try{
            const response = await ApiConnection.post('trips/feedback', data)
            setloading(false)
            if(response?.data?.success){
                setcancelmodal(false)
                toast.success(response?.data?.message)
                GetTrip()
            }

        } catch(e){  setloading(false)}
    }
}




  return (
    <div className='afterlog-sec'>
        {loading && <Loader/>}
    <div className='container'>
        <div className='row'>
            <div className='col-lg-3'>
                <LoginSidebar />
            </div>
            <div className='col-lg-9'>
            <div className='afterlog-sec-right'>
            <h2 className='headingStyle text-left'>Completed Rides</h2>

{triplist.length > 0 ?
    triplist&&triplist.map((item, i)=>{
        return (
            <div className='ride-list'>
            <ul>
            <li>
                            <p><b>Pickup Date: </b>: {moment(item?.pickup_date).format('DD-MM-YYYY')} </p>
                        </li>
                        <li>
                            <p><b>Pickup Time: </b>: {moment(item?.pickup_date).format('LT')} </p>
                        </li>
                        <li>
                            <p><b>Pickup Location: </b>: {item?.pickup_location}  </p>
                        </li>
                        <li>
                            <p><b>Drop Location: </b> {item?.drop_location}  </p>
                        </li>
                        <li>
                            <p><b>Number of Stops: </b> {item?.stops.length} </p>
                        </li>
                        <li>
                            <p><b>Estimated Fare: </b>  $ {item?.final_fare} </p>
                        </li>
            </ul>
            <div className='ride-button'>
                <button className='btn btn-sm btn-primary' onClick={()=>RebookHandle(item?.id)}>Rebook</button>
                <button className='btn btn-sm btn-danger' onClick={()=>rateHandle(item?.id)}>Rate Driver</button>
            </div>
        </div>
        )
    })
:

<h5>No Record found</h5>

}

            
            </div>  
        </div>  
        </div>  
    </div>
    <Modal
        show={cancelmodal}
        centered
        onHide={handleClose}
      >
 <Modal.Header closeButton>
          <Modal.Title>Rate Your Trip
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-left py-2'>
            <div className='form-group'>
                <Rating
                onClick={handleRating}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                onPointerMove={onPointerMove}
                /* Available Props */
            />
            </div>
            <div className='form-group'>
                <label>Your Feedback
                </label>
                <textarea className='form-control' placeholder='Your Feedback' value={feedback} onChange={(e)=>setfeedback(e.target.value)}></textarea>
            </div>
            <div className='form-group text-right'>
                <button className='btn btn-primary' onClick={SubmitHandle}>Submit</button>
            </div>
        </Modal.Body>
      
      </Modal>
</div>
  )
}

export default CompletedRides