import React from 'react'
import { IMAGE } from '../../common/Theme'
import { BaseUrl } from '../../utils/ApiConnection'

const Weare = ({data, sitedata}) => {
  return (
    <div className='we-are'>
    <div className='container'>
        <h2 className='headingStyle'>{sitedata?.the_box_heading}</h2>
        <div className='row'>
            {data&&data.map((item, index)=>{
                return (
                    <div className='col-lg-4' key={index}>
                    <div className='weaer-card'>
                        <img src={BaseUrl + item?.image} alt="ride" />
                        <div className='weaer-card-content'>
                            <h3>{item?.author}</h3>
                            <div dangerouslySetInnerHTML={{ __html: item?.description }} />                     
                               </div>
                    </div>
                </div>
                )
            })}
          
        </div>
    </div>
</div>
  )
}

export default Weare