import React from 'react'
import { NavLink } from 'react-router-dom'

const LoginSidebar = () => {

const LogoutHandle = () =>{
    localStorage.clear();
    window.location.reload()
}



  return (
    <div className='sidebar bg-primary'>
        <ul>
            <li>
                <NavLink to="/dashboard"  className={({ isActive }) => (isActive ? "active" : undefined)}>Dashboard</NavLink>
            </li>
            <li>
                <NavLink to="/new-rides"  className={({ isActive }) => (isActive ? "active" : undefined)}>New Rides</NavLink>
            </li>
            <li>
                <NavLink to="/upcoming-rides"  className={({ isActive }) => (isActive ? "active" : undefined)}>Upcoming Rides</NavLink>
            </li>
            <li>
                <NavLink to="/complete-rides"  className={({ isActive }) => (isActive ? "active" : undefined)}>Completed Rides</NavLink>
            </li>
            <li>
                <NavLink to="/cancel-rides"  className={({ isActive }) => (isActive ? "active" : undefined)}>Cancel Rides</NavLink>
            </li>
            <li>
                <NavLink to="/update-profile"  className={({ isActive }) => (isActive ? "active" : undefined)}>Update Profile</NavLink>
            </li>
            <li>
                <NavLink to="/change-password"  className={({ isActive }) => (isActive ? "active" : undefined)}>Change Password</NavLink>
            </li>
            <li>
                <button className='logOutbtn' onClick={LogoutHandle}>Log Out</button>
            </li>
        </ul>
    </div>
  )
}

export default LoginSidebar