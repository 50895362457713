import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { IMAGE } from './Theme'
import Modal from 'react-bootstrap/Modal';
import { sidebarAction } from '../redux/SidebarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosRequest } from '../utils/AxiosRequest';
import ApiConnection from '../utils/ApiConnection';

const Header = ({data}) => {
    const [isopen, setisopen] = useState(false);
    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.SidebarSlice.isSidebarshow)
    var width = window.innerWidth < 768;
    const token = localStorage.getItem('ride_access_token');

    const GetProfile = async ()=>{
     
        try {
            const  response = await ApiConnection.get('get-profile')
            if(response?.status == 200){

            } else{
              
            }  
        } catch(err){
            if(err.response.status === 401){
               localStorage.clear();
               window.location.reload()
            
          }
        }
    }


    function getLocation() {
        if (navigator.geolocation) {
          navigator.permissions.query({name:'geolocation'}).then(permissionStatus => {
            if (permissionStatus.state === 'denied') {
              alert('Please allow location access.');
              window.location.href = "app-settings:location";
            } else {
              navigator.geolocation.getCurrentPosition(success, error);
            }
          });
        } else {
          alert('Geolocation is not supported in your browser.');
        }
      }

      const success = async (pos) => {
        var crd = pos.coords;
    
     
    }
    
    const error = (err) => {
        alert(`ERROR(${err.code}): ${err.message}`);
    }

      useEffect(()=>{
       // GetProfile()
       //getLocation()
      },[])
 

  return (
    <div className='header'>
        <div className='container'>
        <div className='row'>
            <div className='col-lg-3 col-5'>
                <NavLink to="/">
                    <img src={IMAGE.logo} alt="log" className='logo' />
                 </NavLink>
            </div>
            <div className='col-lg-9 col-7'>
              <div className='header-info'>
                    <ul>
                        <li>
                            <h5><img src={IMAGE.phone_call} alt="hone" /> {data?.header_call_text} <br></br> <b>{data?.phone_number}</b></h5>
                        </li>
                        <li>
                             <div className='header-location'>
                             <h5><img src={IMAGE.globe_light} alt="hone" /> {data?.select_location} </h5>
                             
                             <select>
                                    <option>Select Location</option>
                                </select>    
                            </div>   
                        </li>
                        <li>
                            {token != null ?
                             <NavLink to="/dashboard" className="header_btn">Dashboard</NavLink>
                            :

                              <NavLink to="/login" className="header_btn">Login/Lets Ride</NavLink>
                        }
                      
                            {/* <button className="header_btn" onClick={()=>setloginmodal(true)}>
                                Login/Register
                            </button> */}
                        </li>
                    </ul>
                </div>  
                <div className='menu-container'>
                    <ul className='for_mobile'>
                        <li>
                             <NavLink to="/"  className={({ isActive }) => (isActive ? "active" : undefined)}> 
                             <span>
                                <img src={IMAGE.home} />
                             </span>
                             Welcome
                             </NavLink>
                        </li>
                           {token != null ?
                             <li>
                             <NavLink to="/dashboard"  className={({ isActive }) => (isActive ? "active" : undefined)}> 
                             <span>
                                <img src={IMAGE.user} />
                             </span>
                             Dashboard
                             </NavLink>
                        </li>
                        :
                        <li>
                             <NavLink to="/login"  className={({ isActive }) => (isActive ? "active" : undefined)}> 
                             <span>
                                <img src={IMAGE.user} />
                             </span>
                             Login/Lets Ride
                             </NavLink>
                        </li>
                        }
                        
                        <li>
                        <button  onClick={() =>{
                        setisopen(!isopen)
                        dispatch(sidebarAction(!sidebarShow)) 
                        }
                             
                                }>
                             <span>
                                <img src={IMAGE.menu} />
                             </span>
                             More
                             </button>
                        </li>
                    </ul>
                    <ul className={sidebarShow ? 'main-menu active' : 'main-menu'} >
                        {!width &&
                        <li>
                            <NavLink to="/"  className={({ isActive }) => (isActive ? "active" : undefined)}>Welcome</NavLink>
                        </li>
}
<li>
                            <NavLink  to="/about-us" className={({ isActive }) => (isActive ? "active" : undefined)}>Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="/you-know"  className={({ isActive }) => (isActive ? "active" : undefined)}>Now You Know</NavLink>
                        </li>
                         {token != null ?
 <li>
 <NavLink  to="/book-ride"  className={({ isActive }) => (isActive ? "active" : undefined)}>Lets Ride</NavLink>
</li>
:
<li>
<NavLink to="/login" >Lets Ride</NavLink>
</li>


                        }
                       
                       
                      
                        <li>
                            <NavLink to="/live-streaming"  className={({ isActive }) => (isActive ? "active" : undefined)}>Lets Play</NavLink>
                        </li>
                        <li>
                            <NavLink to="/week-winners"  className={({ isActive }) => (isActive ? "active" : undefined)}>FYI</NavLink>
                        </li>
                        <li>
                            <NavLink to="/faq"  className={({ isActive }) => (isActive ? "active" : undefined)}>FAQ</NavLink>
                        </li>
                       
                    </ul>
                </div>    
            </div>
        </div>
        </div>
        {/* <Modal
        show={loginmodal}
        centered
      >

        <Modal.Body className='text-center py-5'>


        <h5> Please login to book a ride.   <NavLink to="/login">
                    here to Login.
                            </NavLink></h5>
        </Modal.Body>
      
      </Modal> */}
    </div>
  )
}

export default Header