import React,{useEffect, useState} from 'react'
import { IMAGE } from '../../common/Theme'
import { useDispatch, useSelector } from 'react-redux';
import { sidebarAction } from '../../redux/SidebarSlice';

const ContactUs = () => {
    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    const sidebarShow = useSelector((state) => state.SidebarSlice.isSidebarshow)
    const dispatch = useDispatch()
    useEffect(()=>{
      dispatch(sidebarAction(!sidebarShow)) 
    },[])

  return (
    <div className='about-sec'>
    <div className='container'>
        <h2 className='headingStyle'>Contact Us</h2>
        <div className='row'>
        
        <div className='col-lg-6'>
            <div className='form-group'>
                <label>First Name</label>
                <input type="text" className="form-control" placeholder="First Name"
                value={email}
                onChange={(e)=>setemail(e.target.value)}
                />
            </div>
            </div>
            <div className='col-lg-6'>
            <div className='form-group'>
                <label>Last Name</label>
                <input type="text" className="form-control" placeholder="Last Name"
                value={email}
                onChange={(e)=>setemail(e.target.value)}
                />
            </div>
            </div>
            <div className='col-lg-6'>
            <div className='form-group'>
                <label>Email</label>
                <input type="email" className="form-control" placeholder="Email"
                value={email}
                onChange={(e)=>setemail(e.target.value)}
                />
            </div>
            </div>
            <div className='col-lg-6'>
            <div className='form-group'>
                <label>Phone Number</label>
                <input type="email" className="form-control" placeholder="Phone Number"
                value={email}
                onChange={(e)=>setemail(e.target.value)}
                />
            </div>
            </div>
            <div className='col-lg-6'>
            <div className='form-group'>
                <label>Subject</label>
                <input type="text" className="form-control" placeholder="Subject"
                value={email}
                onChange={(e)=>setemail(e.target.value)}
                />
            </div>
            </div>
            <div className='col-lg-12'>
            <div className='form-group'>
                <label>Message</label>
               <textarea className='form-control' placeholder='Message'></textarea>
            </div>
            </div>
     <div className='col-lg-12 mt-5'>
     <div className='form-group text-right'>
                <button type='button' className='submitBtn' >Submit</button>
            </div>
            </div>
      
    </div>
    </div>
</div>
  )
}

export default ContactUs