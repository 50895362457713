import React from 'react'
import LoginSidebar from './LoginSidebar'

const Dashboard = () => {
  return (
    <div className='afterlog-sec'>
    <div className='container'>
        <div className='row'>
            <div className='col-lg-3'>
                <LoginSidebar />
            </div>
            <div className='col-lg-9'>
            <div className='afterlog-sec-right'>
            <h2 className='headingStyle text-left'>Dashboard</h2>
        <div className='row'>
            <div className='col-lg-4'>
                <div className='card bg-primary text-white p-3 d-flex'>
                    <h5>Total Rides                   
                    </h5>
                    <b>18</b>
                </div>
            </div>
            <div className='col-lg-4'>
                <div className='card bg-primary text-white p-3 d-flex'>
                    <h5>Upcoming Rides                   
                    </h5>
                    <b>18</b>
                </div>
            </div>
            <div className='col-lg-4'>
                <div className='card bg-primary text-white p-3 d-flex'>
                    <h5>Completed Rides                   
                    </h5>
                    <b>18</b>
                </div>
            </div>
        </div>
            </div>
        </div>
        </div>
    </div>
</div>
  )
}

export default Dashboard