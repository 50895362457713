import React,{useEffect, useState} from 'react'
import { IMAGE } from '../../common/Theme'
import { useDispatch, useSelector } from 'react-redux';
import { sidebarAction } from '../../redux/SidebarSlice';
import Accordion from 'react-bootstrap/Accordion';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { AxiosRequest } from '../../utils/AxiosRequest';
import Loader from '../../utils/Loader';



const FaqQuestion = () => {


  const sidebarShow = useSelector((state) => state.SidebarSlice.isSidebarshow)
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(sidebarAction(!sidebarShow)) 
  },[])


  const {faqList, GetFaq, loading, faqcat, GetFaqcategory} = AxiosRequest()
  const [tabs, settabs] = useState(0)
 
  useEffect(()=>{
    GetFaqcategory()
  },[])



  
  const TabHandle = (index, id) =>{
    settabs(index)
    GetFaq(id)
  }

  console.log('ta', faqcat[0]?.id)

  return (

    <div className='faq-sec'>
      {loading && <Loader/>}
    <div className='container'>
       <h2 className='headingStyle'>Frequently Asked Questions
       </h2>
       <ul className='mb-3 nav nav-tabs'>
        {faqcat&&faqcat.map((item, i)=>{
          return (
            <li key={i}>
            <button onClick={()=>TabHandle(i, item?.id)} className={tabs == i ? 'nav-link active': 'nav-link'}>{item?.category_name}</button>
        </li>
          )
        })}
       
      </ul>
      <Accordion defaultActiveKey="0">
        {faqList.length > 0 ? 
        faqList&&faqList.map((item, index)=>{
          return (
            <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>{item?.question}</Accordion.Header>
            <Accordion.Body>
             {item?.answer}
            </Accordion.Body>
          </Accordion.Item>
          )
        })
        :
        <h4>No data found</h4>
    }


</Accordion>


   
   </div>
</div>
  )
}

export default FaqQuestion